<template>
  <v-col class="ma-0 pa-0">
    <v-data-table v-model="selected" :headers="headers" :items="contracts" show-select show-expand
      :items-per-page="5" item-key="id" mobile-breakpoint="0" :expanded.sync="expanded" style="backgroud-color: transparent">
      <!--      <template v-slot:item.data-table-select="{isSelected, select}">-->
      <!--        <v-checkbox @click.native.stop.prevent-->
      <!--                    :value="isSelected" @input="select($event)" primary hide-details class="pl-1"-->
      <!--        ></v-checkbox>-->
      <!--      </template>-->
      <template v-slot:item.level="props">
        <v-row class="mt-2">
          <v-icon size="20" :color="props.item.level" class="mr-2"
            v-if="props.item.deliveryHistory && props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 0 &&
              $moment().diff($moment(props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt).tz('Asia/Seoul').format('YYYY-MM-DD'),'days') > 2">
            mdi-clock-alert
          </v-icon>
          <v-icon size="20" :color="props.item.level" class="mr-2"
            v-else-if="props.item.deliveryState === 0 && $moment().diff($moment(props.item.date0).tz('Asia/Seoul').format('YYYY-MM-DD'),'days') > 2">
            mdi-clock-alert
          </v-icon>
          <div v-else-if="props.item.level === 'diamond'">
            <diamond-icon/>
          </div>
          <div v-else>
            <v-icon size="20" :color="props.item.level" class="mr-2" >mdi-hexagon</v-icon>
          </div>
          <span v-if="company.type === 'S'">{{ props.item.username }}</span>
          <a v-else target="_blank" :href="`https://blog.naver.com/${props.item.outerId}`" style="color: rgba(0,0,0,.87);">{{ props.item.username }}</a><br />
          <span class="ml-6" :class="props.item.type === 'insta' ? 'pink--text text--darken-2': 'green--text'">{{ props.item.type === 'insta' ? '인스타' : '네이버' }}</span>
        </v-row>
      </template>
      <template v-slot:item.delivery="props">
        <v-col>
          <v-col v-for="(key, idx) in ['name','contact','id','email','address','additionalInfo','request',]"
            :key="idx" class="pa-0 ma-0">
            <v-row v-if="props.item.delivery[key]" class="my-1">
              <v-col v-if="key === 'name'" class="font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">실명</v-col>
              <v-col v-if="key === 'contact'" class="font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">연락처</v-col>
              <v-col v-if="key === 'email'" class="font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">자사몰 ID</v-col>
              <v-col v-if="key === 'id'" class="font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">자사몰 ID</v-col>
              <v-col v-if="key === 'address'" class="font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">주소</v-col>
              <v-col v-if="key === 'additionalInfo'" class="font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">추가질문</v-col>
              <v-col v-if="key === 'request'" class="font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">요청사항</v-col>
              <v-col class="text-left pa-0">{{props.item.delivery[key] }}</v-col>
            </v-row>
          </v-col>
        </v-col>
      </template>
      <template v-slot:item.purchaseThumbnail="props">
        <span v-if="props.item.purchaseThumbnail" style="
            color: #bd1328;
            text-decoration-line: underline;
            cursor: pointer;
          " @click="openImage(props.item.purchaseThumbnail)">등록</span>
        <span v-else>미등록</span>
      </template>
      <template v-slot:item.deliveryState="props">
        <td class="pa-0" style="min-width: 80px" v-if="props.item.deliveryHistory">
          <span v-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 2">
            기한만료
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 4 && props.item.deliveryMethod === 'A'" class="primary--text">
            발송 준비중
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 4" class="primary--text">
            적립 준비중
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 3" class="french-blue--text">
            재적립
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 1 && props.item.deliveryMethod === 'A'" class="french-blue--text">
            발송완료
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 1" class="french-blue--text">
            적립완료
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === -2">
            정보수정요청
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === -1" class="primary--text">
            정보수정완료
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 10" class="primary--text">
            이용취소
          </span>
          <span v-else-if="props.item.deliveryMethod === 'PR' && [0, 1, 2].includes(props.item.rewardStatus)" class="primary--text">
            구매완료
          </span>
          <span v-else class="primary--text"> 신청완료 </span><br />
          <span :class="$moment().diff($moment(props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt).tz('Asia/Seoul').format('YYYY-MM-DD'),'days') > 2? 'primary--text': 'grey--text text--darken-1'">{{
              props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt ? new Date(props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt).toLocaleDateString() : '' }}</span>
          <br />
        </td>
        <td class="pa-0" style="min-width: 80px" v-else>
          <span v-if="props.item.deliveryState === 2"> 기한만료 </span>
          <span v-else-if="props.item.deliveryState === 4 && props.item.deliveryMethod === 'A'" class="primary--text">
            발송 준비중
          </span>
          <span v-else-if="props.item.deliveryState === 4" class="primary--text">
            적립 준비중
          </span>
          <span v-else-if="props.item.deliveryState === 3" class="french-blue--text">
            재적립
          </span>
          <span v-else-if="props.item.deliveryState === 1 && props.item.deliveryMethod === 'A'" class="french-blue--text">
            발송완료
          </span>
          <span v-else-if="props.item.deliveryState === 1" class="french-blue--text">
            적립완료
          </span>
          <span v-else-if="props.item.deliveryState === -2" class="primary--text">
            정보수정요청
          </span>
          <span v-else-if="props.item.deliveryState === -1" class="primary--text">
            정보수정완료
          </span>
          <span v-else-if="props.item.deliveryState === 10" class="primary--text">
            이용취소
          </span>
          <span v-else class="primary--text"> 신청완료 </span><br />
          <span :class="$moment().diff($moment(props.item.date0).tz('Asia/Seoul').format('YYYY-MM-DD'),'days') > 2? 'primary--text': 'grey--text text--darken-1'">{{
              props.item.deliveryDate ? new Date(props.item.deliveryDate).toLocaleDateString() : new Date(props.item.date0).toLocaleDateString() }}</span>
        </td>
      </template>
      <template v-slot:expanded-item="props">
        <td colspan="4">
          <div v-if="props.item.deliveryHistory" style="
              border-top: 1px solid #c2c2c2;
              border-top: 1px solid var(--pinkish-grey-color);
            ">
            <v-row v-for="(history, idx) in props.item.deliveryHistory" :key="idx" class="py-4">
              <v-col cols="2" class="text-center">
                <v-icon v-if="history.state === 2" color="white" class="circle warm-grey" size="24">
                  mdi-clock-alert
                </v-icon>
                <v-icon v-else-if="history.state === 4 && props.item.deliveryMethod === 'A'" color="white" class="circle rouge" size="24">
                  mdi-dolly
                </v-icon>
                <v-icon v-else-if="history.state === 4" color="white" class="circle rouge" size="24">
                  mdi-ticket-outlined
                </v-icon>
                <v-icon v-else-if="history.state === 3" color="white" class="circle french-blue" size="24">
                  mdi-ticket-confirmation
                </v-icon>
                <v-icon v-else-if="history.state === 1 && props.item.deliveryMethod === 'A'" color="white" class="circle french-blue" size="24">
                  mdi-cube-send
                </v-icon>
                <v-icon v-else-if="history.state === 1" color="white" class="circle french-blue" size="24">
                  mdi-ticket-confirmation
                </v-icon>
                <v-icon v-else-if="history.state === -2" color="white" class="circle warm-grey" size="24">
                  mdi-message-alert
                </v-icon>
                <v-icon v-else-if="history.state === -1" color="white" class="circle rouge" size="24">
                  mdi-file-check
                </v-icon>
                <v-icon v-else-if="history.state === 10" color="white" class="circle warm-grey" size="24">
                  mdi-clock-alert
                </v-icon>
                <v-icon v-else color="white" class="circle rouge" size="24">
                  mdi-account-check
                </v-icon>
              </v-col>
              <v-col cols="10" :style="idx !== props.item.deliveryHistory.length - 1? 'border-bottom: 1px solid #c2c2c2; border-bottom: 1px solid var(--pinkish-grey-color)': ''">
                <span v-if="history.state === 2">
                  기한만료
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === 4 && props.item.deliveryMethod === 'A'">
                  발송 준비중
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === 4">
                  적립 준비중
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === 3">
                  재적립
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === 1 && props.item.deliveryMethod === 'A'">
                  발송완료
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === 1">
                  적립완료
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === -2">
                  정보수정요청
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === -1">
                  정보수정완료
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else-if="history.state === 10">
                  이용취소
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <span v-else>
                  신청완료
                  <span class="french-blue--text">{{new Date(history.updatedAt).toLocaleDateString() }}</span></span>
                <br /><br />
                <span v-if="history.state === 2">
                  배송 상태가 적립금 기한 만료로 변경되었습니다.</span>
                <span v-else-if="history.state === 4 && props.item.deliveryMethod === 'A'">배송 상태가 발송 준비중으로 변경되었습니다.</span>
                <span v-else-if="history.state === 4">배송 상태가 적립 준비중으로 변경되었습니다.</span>
                <span v-else-if="history.state === 3">배송 상태가 재적립으로 변경되었습니다.</span>
                <span v-else-if="history.state === 1 && props.item.deliveryMethod === 'A'">배송 상태가 발송 완료로 변경되었습니다.</span>
                <span v-else-if="history.state === 1">배송 상태가 적립 완료로 변경되었습니다.</span>
                <span v-else-if="history.state === -2">정보 수정 요청이 회원에게 발송되었습니다.</span>
                <span v-else-if="history.state === -1">정보 수정이 완료되었습니다.</span>
                <span v-else-if="history.state === 10">회원의 이용이 취소되었습니다.</span>
                <span v-else>{{ props.item.level | formatLevel }}등급
                  {{ props.item.username }} 회원님의 신청이
                  완료되었습니다.</span>
                <br />
                <v-row>
                  <v-col cols="9">
                    <span class="warm-grey--text">{{ history.message }}</span>
                  </v-col>
                  <v-col cols="2" v-if="idx === props.item.deliveryHistory.length - 1">
                    <v-btn :color="history.state === -1 ? 'pinkish-grey' : 'rouge'"
                      flat outlined @click="changeDeliveryInfo(props.item)"
                      :disabled="history.state == -1" style="min-width: 130px">정보 수정 요청</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row v-else style="
              border-top: 1px solid #c2c2c2;
              border-top: 1px solid var(--pinkish-grey-color);
            " class="py-4">
            <v-col cols="2" class="text-center">
              <v-icon v-if="props.item.deliveryState === 2" color="white" class="circle warm-grey" size="24">
                mdi-clock-alert
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === 4 && props.item.deliveryMethod === 'A'"
                color="white" class="circle rouge" size="24">
                mdi-dolly
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === 4" color="white" class="circle rouge" size="24">
                mdi-ticket-outlined
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === 3" color="white" class="circle french-blue" size="24">
                mdi-ticket-confirmation
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === 1 && props.item.deliveryMethod === 'A'"
                color="white" class="circle french-blue" size="24">
                mdi-cube-send
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === 1" color="white" class="circle french-blue" size="24">
                mdi-ticket-confirmation
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === -2" color="white" class="circle warm-grey" size="24">
                mdi-message-alert
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === -1" color="white" class="circle rouge" size="24">
                mdi-file-check
              </v-icon>
              <v-icon v-else-if="props.item.deliveryState === 10" color="white" class="circle warm-grey" size="24">
                mdi-clock-alert
              </v-icon>
              <v-icon v-else color="white" class="circle rouge" size="24">
                mdi-account-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <span v-if="props.item.deliveryState === 2"> 기한만료</span>
              <span v-else-if="props.item.deliveryState === 4 &&props.item.deliveryMethod === 'A'">
                발송 준비중
              </span>
              <span v-else-if="props.item.deliveryState === 4">적립 준비중</span>
              <span v-else-if="props.item.deliveryState === 3"> 재적립</span>
              <span v-else-if="props.item.deliveryState === 1 &&props.item.deliveryMethod === 'A'">
                발송완료
                <span class="french-blue--text">{{new Date(props.item.deliveryDate).toLocaleDateString() }}</span></span>
              <span v-else-if="props.item.deliveryState === 1">
                적립완료
                <span class="french-blue--text">{{props.item.deliveryDate ? new Date(props.item.deliveryDate).toLocaleDateString() : '' }}</span></span>
              <span v-else-if="props.item.deliveryState === -2">정보수정요청</span>
              <span v-else-if="props.item.deliveryState === -1">정보수정완료</span>
              <span v-else-if="props.item.deliveryState === 10"> 이용취소</span>
              <span v-else>
                신청완료
                <span class="french-blue--text">{{new Date(props.item.date0).toLocaleDateString() }}</span></span>
              <br /><br />
              <span v-if="props.item.deliveryState === 2">
                배송 상태가 적립금 기한 만료로 변경되었습니다.</span>
              <span v-else-if="props.item.deliveryState === 4 && props.item.deliveryMethod === 'A'">배송 상태가 발송 준비중으로 변경되었습니다.</span>
              <span v-else-if="props.item.deliveryState === 4">배송 상태가 적립 준비중으로 변경되었습니다.</span>
              <span v-else-if="props.item.deliveryState === 3">배송 상태가 재적립으로 변경되었습니다.</span>
              <span v-else-if="props.item.deliveryState === 1 && props.item.deliveryMethod === 'A'">배송 상태가 발송 완료로 변경되었습니다.</span>
              <span v-else-if="props.item.deliveryState === 1">배송 상태가 적립 완료로 변경되었습니다.</span>
              <span v-else-if="props.item.deliveryState === -2">정보 수정 요청이 회원에게 발송되었습니다.</span>
              <span v-else-if="props.item.deliveryState === -1">정보 수정이 완료되었습니다.</span>
              <span v-else-if="props.item.deliveryState === 10">회원의 이용이 취소되었습니다.</span>
              <span v-else>{{ props.item.level | formatLevel }}등급
                {{ props.item.username }} 회원님의 신청이 완료되었습니다.</span>
              <v-row>
                <v-col cols="9">
                  <span class="warm-grey--text"></span>
                </v-col>
                <v-col cols="2">
                  <v-btn :color="props.item.deliveryState === -1 ? 'pinkish-grey' : 'rouge'"
                    flat outlined @click="changeDeliveryInfo(props.item)"
                    :disabled="props.item.deliveryState == -1" style="min-width: 130px">정보 수정 요청</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:footer>
        <td :colspan="headers.length + 1" class="pa-1 text-right">
          <v-row class="mx-0">
            <v-col class="mt-4 text-left" :cols="tap === 0 ? 6 : 12">
              <span class="rouge--text font-weight-bold">총 {{ selected.length }}건 선택</span>
            </v-col>
            <v-col class="mt-1" cols="3">
              <v-select v-if="tap === 0" v-model="deliveryState"
                :items="Object.entries(option.deliveryState[company.deliveryMethod === 'A' ? 'address' : 'id']).map(([k, v]) => ({ value: parseInt(k), text: v })) || []"
                item-text="text" item-value="value" class="pa-0" hide-details flat></v-select>
            </v-col>
            <v-col cols="3">
              <v-btn v-if="tap === 0" color="primary" @click="changDelivery" style="width: 120px">
                배송정보 변경
              </v-btn>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-col class="text-right pa-0 ma-0">
      <download-excel :data="contracts" :fields="json_fields" name="delivery.xls">
        <v-btn color="#61c9a8" class="ma-0 white--text" style="min-width: 150px">엑셀파일 다운로드</v-btn>
      </download-excel>
    </v-col>
    <v-row justify="center">
      <v-dialog v-model="showChangeInfo" content-class="white-two" persistent max-width="500px">
        <v-card class="px-6 pt-2 pb-4">
          <v-card-title class="pb-1">
            <div>
              <v-icon color="default" @click="showChangeInfo = false">close</v-icon>
              <h2 class="mt-4 font-weight-bold headline">정보 수정 요청</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="body-1">
              정보 수정 요청시 회원에게 보여질 메시지를 선택해주세요.
              <v-radio-group v-model="predefinedMessage" hide-details>
                <v-radio color="primary"
                  label="자사몰 아이디를 조회할 수 없습니다. 홈페이지 가입여부를 확인 후 다시 입력해주세요."
                  value="자사몰 아이디를 조회할 수 없습니다. 홈페이지 가입여부를 확인 후 다시 입력해주세요."></v-radio>
                <v-radio color="primary"
                  label="자사몰 아이디를 이메일 형식으로 다시 입력해주세요."
                  value="자사몰 아이디를 이메일 형식으로 다시 입력해주세요."></v-radio>
                <v-radio color="primary"
                  label="자사몰 아이디를 이메일 형식이 아닌 일반 아이디 형식으로 입력해주세요."
                  value="자사몰 아이디를 이메일 형식이 아닌 일반 아이디 형식으로 입력해주세요."></v-radio>
                <v-radio color="primary"
                  label="연락처가 잘못 입력되었습니다."
                  value="연락처가 잘못 입력되었습니다."></v-radio>
                <v-radio color="primary" label="직접 입력" value="직접 입력"></v-radio>
              </v-radio-group>
              <v-text-field v-model="deliveryMessage" name="deliveryMessage" type="text" class="pl-6 ml-2 mt-2"
                single-line outlined solo flat hide-details v-if="predefinedMessage === '직접 입력'"></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="rouge" flat outlined @click.native="showChangeInfo = false">취소</v-btn>
            <v-btn class="rouge white-two--text ma-0 ml-4" flat @click="showChangeInfo = false;sendRequest();">요청 보내기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import JsonExcel from 'vue-json-excel';
import Vue from 'vue';

import companyMixin from '@/components/shared/companyMixin';

export default {
  name: 'Product',
  mixins: [companyMixin],
  props: ['contracts', 'option', 'tap'],
  components: {
    diamondIcon: () => import('@/assets/img/regads/diamond.svg'),
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      headers: [
        { text: '등급 ∙ 회원명', value: 'level', sortable: false, width: 120 },
        {
          text: '배송 정보',
          align: 'left',
          value: 'delivery',
          sortable: false,
          width: 330,
        },
        {
          text: '배송 상태',
          align: 'left',
          value: 'deliveryState',
          sortable: false,
          width: 50,
        },
        { text: '', value: 'data-table-expand' },
      ],
      selected: [],
      expanded: [],
      showDialog: false,
      contract: {},
      rowsPerPage: 5,
      deliveryState: 0,
      showChangeInfo: false,
      predefinedMessage:
        '자사몰 아이디를 조회할 수 없습니다. 홈페이지 가입여부를 확인 후 다시 입력해주세요.',
      deliveryMessage: '',
      json_fields: {
        '업데이트 일자': {
          callback: () => {
            return Vue.moment(new Date())
              .tz('Asia/Seoul')
              .format('YYYY-MM-DD HH:MM');
          },
        },
        광고명: 'companyName',
        회원명: 'username',
        등급: 'level',
        채널명: {
          field: 'type',
          callback: (value) => {
            if (value === 'insta') {
              return '인스타';
            } else {
              return '네이버';
            }
          },
        },
        '이용 일자': {
          callback: (value) => {
            return Vue.moment(value.date0)
              .tz('Asia/Seoul')
              .format('YYYY-MM-DD HH:MM');
          },
        },
        '배송 상태': {
          callback: (value) => {
            if (value.deliveryState === 2) {
              return '기한만료';
            } else if (value.deliveryState === 3) {
              return '재적립';
            } else if (
              value.deliveryState === 1 &&
              value.deliveryMethod === 'A'
            ) {
              return '발송완료';
            } else if (value.deliveryState === 1) {
              return '적립완료';
            } else if (
              value.deliveryState === 4 &&
              value.deliveryMethod === 'A'
            ) {
              return '발송준비';
            } else if (value.deliveryState === 4) {
              return '적립준비중';
            } else {
              return '확인중';
            }
          },
        },
        '배송정보: 실명': 'delivery.name',
        '배송정보: 연락처': {
          field: 'delivery.contact',
          callback: (value) => {
            if (value) {
              return `${value}`;
            } else {
              return value;
            }
          },
        },
        '배송정보: 자사몰 아이디(이메일 형식)': 'delivery.email',
        '배송정보: 자사몰 아이디(일반 아이디 형식)': 'delivery.id',
        '배송정보: 주소': 'delivery.address',
        '배송정보: 추가질문': 'delivery.additionalInfo',
        '배송정보: 배송시 요청사항': 'delivery.request',
      },
    };
  },
  methods: {
    openImage(link) {
      window.open(link, '_blank');
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.contracts.slice();
      }
    },
    changDelivery() {
      Promise.all(
        this.selected.map((contract) => {
          if (!contract.deliveryHistory) {
            contract.deliveryHistory = [];
            let date = new Date(contract.date0);
            if (contract.deliveryState === 1) {
              date = contract.deliveryDate
                ? new Date(contract.deliveryDate)
                : new Date();
            }
            contract.deliveryHistory.push({
              state: contract.deliveryState,
              updatedAt: date,
              message: '',
            });
          }
          console.log(contract);
          if (
            this.deliveryState !=
            contract.deliveryHistory[contract.deliveryHistory.length - 1].state
          ) {
            contract.deliveryHistory.push({
              state: parseInt(this.deliveryState),
              updatedAt: new Date(),
              message: '',
            });
            return this.axios.put(
              '/contract?id=' + (contract.id || contract.rKey),
              {
                user: contract.user,
                company: contract.company,
                delivery: contract.delivery,
                deliveryState: parseInt(this.deliveryState),
                deliveryHistory: contract.deliveryHistory,
              }
            );
          } else {
            return null;
          }
        })
      );
    },
    showReview(contract) {
      let reviewWindow = window.open();
      reviewWindow.location =
        'https://blog.naver.com/' +
        String(contract.userEmail || '').split('@')[0] +
        '/' +
        contract.link;
    },
    review(contract) {
      this.showDialog = true;
      this.contract = contract;
    },
    changeDeliveryInfo(contract) {
      this.showChangeInfo = true;
      this.contract = contract;
    },
    sendRequest() {
      if (!this.contract.deliveryHistory) {
        this.contract.deliveryHistory = [];
        let date = new Date(this.contract.date0);
        if (this.contract.deliveryState === 1) {
          date = this.contract.deliveryDate
            ? new Date(this.contract.deliveryDate)
            : new Date();
        }
        this.contract.deliveryHistory.push({
          state: this.contract.deliveryState,
          updatedAt: date,
          message: '',
        });
      }
      this.contract.deliveryHistory.push({
        state: -2,
        updatedAt: new Date(),
        message:
          this.predefinedMessage === '직접 입력'
            ? this.deliveryMessage
            : this.predefinedMessage,
      });
      this.axios.put(
        '/contract?id=' + (this.contract.id || this.contract.rKey),
        {
          user: this.contract.user,
          company: this.contract.company,
          deliveryHistory: this.contract.deliveryHistory,
        }
      );
    },
  },
  watch: {
    tap(value) {
      console.log(this.contracts);

      const headerBase = [
        { text: '등급 ∙ 회원명', value: 'level', sortable: false, width: 120 },
        {
          text: '배송 정보',
          align: 'left',
          value: 'delivery',
          sortable: false,
          width: 330,
        },
        {
          text: '배송 상태',
          align: 'left',
          value: 'deliveryState',
          sortable: false,
          width: 50,
        },
        { text: '', value: 'data-table-expand' },
      ];

      if (value === 0) {
        this.headers = headerBase;
      } else if (value === 1) {
        const newHeader = [...headerBase];
        newHeader.splice(2, 0, {
          text: '구매 내역',
          align: 'left',
          value: 'purchaseThumbnail',
          sortable: false,
          width: 80,
        });

        this.headers = newHeader;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-expansion-panel__header {
  padding: 0px;
}

::v-deep .v-text-field--outline.v-text-field--single-line input {
  margin-top: 0;
}

::v-deep .v-text-field--outline.v-text-field--single-line .v-input__slot {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #c2c2c2;
  border: solid 1px var(--pinkish-grey-color);
}

::v-deep .v-label {
  font-size: 14px;
}

.circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
</style>
